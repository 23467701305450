import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // 红包获客
  {
    path: '/redEnvelope',
    name: 'redEnvelopeIndex',
    component: () => import(/* webpackChunkName: "redEnvelope" */ '@/views/redEnvelope/index'),
  },
  // 推广链接
  {
    path: '/redEnvelope/link', 
    component: () => import(/* webpackChunkName: "redEnvelope" */ '@/views/redEnvelope/link'),
  },
  {
    path: '/redEnvelope/speed',
    component: () => import(/* webpackChunkName: "redEnvelope" */ '@/views/redEnvelope/speed'),
  },
  {
    path: '/redEnvelope/speed2',
    component: () => import(/* webpackChunkName: "redEnvelope" */ '@/views/redEnvelope/speed2'),
  },
  {
    path: '/redEnvelope/test',
    component: () => import(/* webpackChunkName: "redEnvelope" */ '@/views/redEnvelope/test'),
  },
  {
    path: '/redEnvelope/redlist',
    component: () => import(/* webpackChunkName: "redEnvelope" */ '@/views/redEnvelope/redlist'),
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
